.container {
  width: 100%;
  height: 100vh;
  margin: 20px;
  place-content: center;
  place-items: center;
}

.button-container {
  display: flex;
  flex-flow: row;
  margin-left: 10px;
}
