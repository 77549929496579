.container {
  height: 100px;
  width: fit-content;
  border-radius: 1%;
  margin-bottom: 50px;
}

.question-text {
  font-size: 50px;
  font-weight: 800;
}
