.button-group {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: flex;
}

.info-text {
  margin: 2% 10%;
}

.divider {
  width: 100%;
  border-bottom: solid 1px grey;
}

.result-text {
  font-size: 50px;
  font-weight: 800;
  color: black;
  text-align-last: center;
  margin-top: 30px;
}

.result-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.revenue-enhance {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.revenue-text {
  margin: 0;
  font-weight: 600;
}
