.category-name {
  font-size: 30px;
  font-weight: 800;
  color: black;
  text-align-last: center;
  margin-top: 30px;
}

.question-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.error {
  font-weight: 600;
  color: red;
  text-align-last: center;
  margin-bottom: 10px;
}
