.progress-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  width: 100%;
  height: 8px;
  background-color: whitesmoke;
  border-radius: 8px;
  margin: 15px;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: cornflowerblue;
  border-radius: 8px;
  text-align: center;
}

.progress-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
