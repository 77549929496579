html {
  height: 100%;
  /* background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 5%); */
  /* overflow: hidden; */
}

#stars {
  overflow-y: hidden;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1355px 245px #fff, 976px 126px #fff, 1641px 392px #fff,
    251px 510px #fff, 1891px 1310px #fff, 773px 1985px #fff, 949px 1649px #fff,
    1279px 980px #fff, 470px 1740px #fff, 1684px 501px #fff, 521px 992px #fff,
    1760px 563px #fff, 1633px 81px #fff, 1181px 204px #fff, 1779px 179px #fff,
    340px 1627px #fff, 746px 1862px #fff, 1921px 461px #fff, 1234px 1578px #fff,
    1569px 1991px #fff, 94px 1632px #fff, 1485px 805px #fff, 1765px 652px #fff,
    31px 1992px #fff, 886px 1721px #fff, 487px 189px #fff, 602px 1401px #fff,
    681px 1316px #fff, 1706px 1964px #fff, 1744px 1556px #fff, 131px 296px #fff,
    1270px 1972px #fff, 39px 976px #fff, 1736px 1695px #fff, 148px 1485px #fff,
    842px 1308px #fff, 1103px 548px #fff, 1785px 585px #fff, 656px 1619px #fff,
    1210px 1598px #fff, 1667px 238px #fff, 1376px 1523px #fff,
    1428px 1394px #fff, 594px 1205px #fff, 1301px 1240px #fff, 1307px 926px #fff,
    865px 874px #fff, 762px 353px #fff, 233px 131px #fff, 415px 583px #fff,
    843px 1575px #fff, 630px 1902px #fff, 1580px 488px #fff, 167px 1529px #fff,
    1798px 117px #fff, 1397px 1082px #fff, 20px 1958px #fff, 1604px 973px #fff,
    804px 227px #fff, 1222px 895px #fff, 1985px 73px #fff, 1715px 1150px #fff,
    671px 215px #fff, 143px 656px #fff, 1445px 984px #fff, 112px 883px #fff,
    236px 1676px #fff, 1167px 1718px #fff, 1811px 1982px #fff, 676px 1647px #fff,
    1203px 1148px #fff, 1144px 1396px #fff, 1209px 1623px #fff,
    532px 1043px #fff, 1264px 841px #fff, 324px 391px #fff, 1913px 1338px #fff,
    28px 436px #fff, 1971px 65px #fff, 947px 739px #fff, 617px 1927px #fff,
    1796px 1529px #fff, 356px 837px #fff, 1987px 11px #fff, 1676px 25px #fff,
    300px 1652px #fff, 634px 321px #fff, 607px 1412px #fff, 991px 249px #fff,
    1242px 1038px #fff, 405px 1280px #fff, 1939px 28px #fff, 1041px 722px #fff,
    454px 42px #fff, 1662px 35px #fff, 836px 1582px #fff, 1682px 1013px #fff,
    565px 1879px #fff, 1481px 1674px #fff, 1435px 1332px #fff,
    1277px 1173px #fff, 136px 375px #fff, 812px 476px #fff, 47px 113px #fff,
    888px 1751px #fff, 1988px 1720px #fff, 265px 1088px #fff, 337px 1524px #fff,
    1638px 431px #fff, 1069px 1195px #fff, 1694px 634px #fff, 1404px 351px #fff,
    1890px 1603px #fff, 1376px 1555px #fff, 83px 1120px #fff, 400px 272px #fff,
    179px 1674px #fff, 1515px 1424px #fff, 1113px 884px #fff, 674px 1002px #fff,
    1948px 1500px #fff, 146px 443px #fff, 1640px 177px #fff, 150px 1089px #fff,
    428px 1824px #fff, 1875px 1054px #fff, 1295px 1661px #fff, 723px 139px #fff,
    1243px 374px #fff, 515px 832px #fff, 639px 297px #fff, 328px 261px #fff,
    1847px 957px #fff, 173px 1245px #fff, 1516px 713px #fff, 1311px 1643px #fff,
    1725px 558px #fff, 1952px 494px #fff, 645px 1937px #fff, 112px 676px #fff,
    188px 791px #fff, 1163px 1384px #fff, 1701px 597px #fff, 1911px 1695px #fff,
    56px 767px #fff, 1452px 1661px #fff, 532px 317px #fff, 1337px 409px #fff,
    929px 1553px #fff, 192px 1308px #fff, 944px 136px #fff, 1743px 1116px #fff,
    1833px 1214px #fff, 898px 293px #fff, 532px 840px #fff, 1299px 24px #fff,
    1598px 517px #fff, 1599px 1283px #fff, 1449px 1766px #fff, 156px 1733px #fff,
    398px 1743px #fff, 1809px 501px #fff, 1570px 986px #fff, 853px 1171px #fff,
    261px 459px #fff, 1731px 1904px #fff, 589px 1690px #fff, 86px 659px #fff,
    860px 838px #fff, 1530px 1495px #fff, 217px 52px #fff, 1133px 335px #fff,
    504px 252px #fff, 1750px 1093px #fff, 1010px 67px #fff, 144px 638px #fff,
    1911px 1270px #fff, 229px 1043px #fff, 1508px 1278px #fff, 899px 473px #fff,
    90px 1204px #fff, 1938px 1721px #fff, 1028px 18px #fff, 1243px 506px #fff,
    1053px 1552px #fff, 1248px 1527px #fff, 1690px 505px #fff, 1106px 274px #fff,
    1739px 1208px #fff, 895px 1242px #fff, 721px 666px #fff, 1280px 113px #fff,
    1899px 1038px #fff, 1671px 488px #fff, 1568px 330px #fff, 1553px 329px #fff,
    702px 754px #fff, 232px 1225px #fff, 385px 918px #fff, 44px 543px #fff,
    1195px 125px #fff, 1106px 272px #fff, 815px 963px #fff, 1449px 129px #fff,
    1837px 1559px #fff, 783px 1057px #fff, 1223px 607px #fff, 808px 269px #fff,
    503px 592px #fff, 1020px 166px #fff, 1468px 790px #fff, 1520px 1444px #fff,
    1901px 152px #fff, 256px 580px #fff, 1881px 1162px #fff, 1180px 1774px #fff,
    1425px 494px #fff, 683px 1955px #fff, 1258px 1713px #fff, 1337px 598px #fff,
    384px 457px #fff, 270px 904px #fff, 227px 228px #fff, 657px 133px #fff,
    189px 968px #fff, 253px 800px #fff, 1949px 1157px #fff, 1392px 76px #fff,
    1115px 1744px #fff, 1022px 740px #fff, 1762px 1560px #fff,
    1603px 1535px #fff, 284px 1451px #fff, 599px 1019px #fff, 1401px 842px #fff,
    276px 875px #fff, 1816px 830px #fff, 1758px 548px #fff, 1411px 305px #fff,
    1437px 1134px #fff, 317px 1331px #fff, 1277px 130px #fff, 1437px 556px #fff,
    1955px 294px #fff, 521px 1212px #fff, 1359px 1578px #fff, 369px 910px #fff,
    1687px 234px #fff, 69px 1995px #fff, 1301px 1037px #fff, 171px 1071px #fff,
    123px 1633px #fff, 367px 347px #fff, 1269px 439px #fff, 1144px 834px #fff,
    381px 1632px #fff, 1511px 877px #fff, 1057px 1886px #fff, 234px 231px #fff,
    1745px 600px #fff, 95px 1217px #fff, 87px 683px #fff, 1315px 165px #fff,
    560px 1072px #fff, 674px 868px #fff, 91px 446px #fff, 1478px 1135px #fff,
    703px 1209px #fff, 846px 430px #fff, 340px 205px #fff, 683px 1521px #fff,
    530px 592px #fff, 249px 1903px #fff, 449px 86px #fff, 1371px 1549px #fff,
    1815px 1474px #fff, 951px 1327px #fff, 1039px 1255px #fff, 967px 1050px #fff,
    1395px 1801px #fff, 1869px 928px #fff, 285px 1778px #fff, 1363px 1353px #fff,
    829px 856px #fff, 47px 101px #fff, 1713px 139px #fff, 1212px 17px #fff,
    1721px 1967px #fff, 979px 1118px #fff, 244px 697px #fff, 190px 387px #fff,
    786px 925px #fff, 596px 1577px #fff, 1522px 913px #fff, 415px 1190px #fff,
    1666px 1362px #fff, 1246px 1135px #fff, 1666px 81px #fff, 361px 419px #fff,
    1511px 433px #fff, 1774px 1580px #fff, 673px 216px #fff, 790px 1825px #fff,
    96px 960px #fff, 1357px 1780px #fff, 1700px 1057px #fff, 29px 765px #fff,
    1457px 625px #fff, 1670px 685px #fff, 667px 1421px #fff, 1065px 244px #fff,
    1644px 1216px #fff, 914px 406px #fff, 1941px 1234px #fff, 234px 1856px #fff,
    695px 1560px #fff, 574px 406px #fff, 723px 1680px #fff, 336px 829px #fff,
    928px 152px #fff, 1321px 250px #fff, 1860px 335px #fff, 1690px 1474px #fff,
    1214px 1405px #fff, 165px 698px #fff, 513px 1081px #fff, 1973px 1669px #fff,
    1685px 69px #fff, 340px 921px #fff, 1791px 1856px #fff, 1799px 1925px #fff,
    350px 985px #fff, 974px 1839px #fff, 988px 462px #fff, 1842px 1396px #fff,
    5px 640px #fff, 1142px 1917px #fff, 704px 491px #fff, 1704px 1869px #fff,
    1765px 1959px #fff, 1095px 1783px #fff, 502px 1405px #fff, 91px 563px #fff,
    1214px 1705px #fff, 254px 67px #fff, 1028px 836px #fff, 916px 770px #fff,
    1625px 798px #fff, 1499px 1661px #fff, 149px 515px #fff, 251px 332px #fff,
    1422px 1281px #fff, 1512px 971px #fff, 463px 617px #fff, 49px 1425px #fff,
    1327px 49px #fff, 604px 331px #fff, 920px 1929px #fff, 903px 1532px #fff,
    1535px 1341px #fff, 1px 1002px #fff, 1870px 1923px #fff, 474px 432px #fff,
    223px 1039px #fff, 1184px 1545px #fff, 1573px 1250px #fff,
    1326px 1052px #fff, 1830px 463px #fff, 847px 1687px #fff, 829px 1826px #fff,
    454px 546px #fff, 1771px 461px #fff, 365px 955px #fff, 1158px 80px #fff,
    1487px 196px #fff, 355px 997px #fff, 1328px 858px #fff, 1562px 223px #fff,
    1241px 1384px #fff, 1755px 1115px #fff, 1532px 162px #fff, 879px 270px #fff,
    1829px 94px #fff, 1718px 312px #fff, 1911px 606px #fff, 23px 1695px #fff,
    362px 305px #fff, 1154px 933px #fff, 30px 1138px #fff, 486px 1362px #fff,
    771px 1548px #fff, 253px 1810px #fff, 1870px 779px #fff, 936px 449px #fff,
    1600px 934px #fff, 205px 674px #fff, 731px 1281px #fff, 544px 1909px #fff,
    537px 229px #fff, 911px 1886px #fff, 266px 1065px #fff, 537px 865px #fff,
    1503px 906px #fff, 1463px 376px #fff, 1450px 893px #fff, 897px 74px #fff,
    1366px 1181px #fff, 1455px 151px #fff, 133px 1160px #fff, 1995px 658px #fff,
    162px 612px #fff, 130px 719px #fff, 1078px 1065px #fff, 418px 1460px #fff,
    1682px 493px #fff, 389px 122px #fff, 718px 659px #fff, 1702px 938px #fff,
    207px 1205px #fff, 1012px 1591px #fff, 1295px 165px #fff, 1626px 1536px #fff,
    340px 773px #fff, 939px 175px #fff, 207px 99px #fff, 204px 210px #fff,
    1088px 35px #fff, 798px 57px #fff, 466px 1766px #fff, 1661px 644px #fff,
    1159px 1598px #fff, 1964px 1162px #fff, 1196px 1476px #fff,
    515px 1527px #fff, 1119px 1765px #fff, 1685px 1624px #fff, 937px 590px #fff,
    1710px 207px #fff, 1626px 53px #fff, 171px 1380px #fff, 928px 98px #fff,
    225px 1969px #fff, 1731px 1986px #fff, 1092px 1924px #fff, 459px 1439px #fff,
    1436px 384px #fff, 1012px 1873px #fff, 1759px 852px #fff, 1353px 802px #fff,
    1839px 1368px #fff, 1398px 1429px #fff, 271px 296px #fff, 1839px 313px #fff,
    392px 963px #fff, 1973px 995px #fff, 895px 1744px #fff, 158px 630px #fff,
    656px 1441px #fff, 558px 1127px #fff, 410px 1514px #fff, 850px 154px #fff,
    1564px 745px #fff, 1020px 1411px #fff, 1892px 446px #fff, 363px 1158px #fff,
    730px 244px #fff, 758px 1824px #fff, 633px 859px #fff, 500px 144px #fff,
    1624px 669px #fff, 1658px 61px #fff, 1313px 974px #fff, 81px 1233px #fff,
    1220px 1937px #fff, 1381px 1820px #fff, 1043px 1717px #fff,
    1351px 426px #fff, 1740px 188px #fff, 124px 251px #fff, 426px 1553px #fff,
    355px 483px #fff, 9px 925px #fff, 1939px 386px #fff, 168px 119px #fff,
    1464px 908px #fff, 120px 257px #fff, 1345px 1514px #fff, 1357px 729px #fff,
    1047px 1352px #fff, 1574px 1574px #fff, 458px 653px #fff, 44px 594px #fff,
    1999px 668px #fff, 574px 1069px #fff, 1293px 1203px #fff, 1175px 965px #fff,
    1912px 1167px #fff, 1497px 942px #fff, 1158px 1107px #fff, 1766px 427px #fff,
    341px 1818px #fff, 765px 1655px #fff, 612px 1470px #fff, 363px 1716px #fff,
    1035px 1206px #fff, 1196px 1641px #fff, 28px 1563px #fff, 911px 935px #fff,
    1153px 158px #fff, 704px 202px #fff, 1750px 109px #fff, 1126px 61px #fff,
    1937px 568px #fff, 278px 1188px #fff, 842px 195px #fff, 135px 1872px #fff,
    1980px 733px #fff, 1636px 1919px #fff, 1832px 544px #fff, 626px 885px #fff,
    1004px 1791px #fff, 1582px 742px #fff, 1253px 441px #fff, 1078px 1914px #fff,
    1562px 1333px #fff, 327px 682px #fff, 15px 928px #fff, 154px 1380px #fff,
    965px 801px #fff, 1554px 1636px #fff, 1249px 1214px #fff, 296px 1440px #fff,
    115px 1307px #fff, 1034px 1427px #fff, 1387px 377px #fff, 1356px 1564px #fff,
    1569px 1431px #fff, 890px 1655px #fff, 1061px 1843px #fff, 858px 356px #fff,
    897px 1667px #fff, 1058px 694px #fff, 291px 402px #fff, 1746px 995px #fff,
    402px 1707px #fff, 580px 1956px #fff, 1781px 514px #fff, 1459px 1130px #fff,
    1678px 929px #fff, 1675px 897px #fff, 1976px 1116px #fff, 1447px 356px #fff,
    375px 663px #fff, 371px 807px #fff, 321px 1750px #fff, 1782px 685px #fff,
    115px 398px #fff, 189px 1312px #fff, 575px 846px #fff, 693px 167px #fff,
    213px 1088px #fff, 556px 1515px #fff, 781px 892px #fff, 1943px 128px #fff,
    147px 430px #fff, 1629px 831px #fff, 1568px 1641px #fff, 1326px 1958px #fff,
    1863px 1998px #fff, 1114px 116px #fff, 814px 231px #fff, 1322px 428px #fff,
    1605px 1376px #fff, 398px 1083px #fff, 1895px 734px #fff, 1031px 838px #fff,
    539px 1304px #fff, 1141px 1091px #fff, 1551px 81px #fff, 357px 1532px #fff,
    785px 309px #fff, 1302px 1189px #fff, 1400px 1453px #fff, 76px 359px #fff,
    1842px 679px #fff, 720px 514px #fff, 1759px 803px #fff, 1915px 962px #fff,
    1426px 1879px #fff, 1103px 133px #fff, 249px 966px #fff, 952px 996px #fff,
    415px 1117px #fff, 1533px 1436px #fff, 950px 576px #fff, 1785px 1515px #fff,
    129px 699px #fff, 704px 292px #fff, 523px 808px #fff, 748px 580px #fff,
    897px 1532px #fff, 1849px 1614px #fff, 1006px 392px #fff, 1686px 753px #fff,
    653px 1042px #fff, 1431px 975px #fff, 260px 1409px #fff, 778px 1846px #fff,
    1403px 1591px #fff, 1382px 459px #fff, 485px 294px #fff, 1478px 1546px #fff,
    696px 1019px #fff, 34px 1091px #fff, 99px 681px #fff, 42px 392px #fff,
    282px 1962px #fff, 1273px 444px #fff, 1546px 66px #fff, 1153px 437px #fff,
    1122px 1942px #fff, 319px 494px #fff, 1160px 1690px #fff, 155px 159px #fff,
    656px 820px #fff, 10px 739px #fff, 1769px 490px #fff, 786px 537px #fff,
    662px 792px #fff, 990px 1452px #fff, 270px 1794px #fff, 1600px 1424px #fff,
    208px 489px #fff, 1084px 1117px #fff, 1211px 302px #fff, 1595px 1171px #fff,
    450px 616px #fff, 432px 789px #fff, 1003px 1104px #fff, 788px 939px #fff,
    1433px 759px #fff, 852px 1392px #fff, 1152px 1284px #fff, 1255px 1375px #fff,
    378px 1008px #fff, 1085px 934px #fff, 1512px 830px #fff, 311px 1892px #fff,
    853px 387px #fff, 1643px 1537px #fff, 702px 107px #fff, 1499px 718px #fff,
    1403px 92px #fff, 1161px 759px #fff, 1215px 653px #fff, 1213px 933px #fff,
    809px 1146px #fff, 274px 1570px #fff, 1690px 980px #fff, 1239px 621px #fff,
    577px 445px #fff, 390px 495px #fff, 1253px 895px #fff, 153px 175px #fff,
    606px 573px #fff, 916px 1816px #fff, 1770px 1266px #fff, 391px 1265px #fff,
    562px 345px #fff, 658px 1336px #fff, 488px 1185px #fff, 1699px 1913px #fff,
    1381px 1158px #fff, 1656px 484px #fff, 1694px 727px #fff, 1666px 696px #fff,
    556px 566px #fff, 905px 363px #fff, 367px 409px #fff, 185px 422px #fff,
    1516px 1970px #fff, 853px 125px #fff, 572px 1422px #fff, 374px 40px #fff,
    1988px 1681px #fff, 26px 1108px #fff, 1255px 1524px #fff, 168px 1350px #fff,
    1717px 81px #fff, 1036px 869px #fff, 1522px 92px #fff, 812px 1294px #fff,
    232px 554px #fff, 197px 157px #fff, 1475px 1629px #fff, 1045px 1471px #fff,
    1654px 1248px #fff, 173px 1701px #fff, 1698px 120px #fff, 278px 1998px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  overflow-y: hidden;
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1355px 245px #fff, 976px 126px #fff, 1641px 392px #fff,
    251px 510px #fff, 1891px 1310px #fff, 773px 1985px #fff, 949px 1649px #fff,
    1279px 980px #fff, 470px 1740px #fff, 1684px 501px #fff, 521px 992px #fff,
    1760px 563px #fff, 1633px 81px #fff, 1181px 204px #fff, 1779px 179px #fff,
    340px 1627px #fff, 746px 1862px #fff, 1921px 461px #fff, 1234px 1578px #fff,
    1569px 1991px #fff, 94px 1632px #fff, 1485px 805px #fff, 1765px 652px #fff,
    31px 1992px #fff, 886px 1721px #fff, 487px 189px #fff, 602px 1401px #fff,
    681px 1316px #fff, 1706px 1964px #fff, 1744px 1556px #fff, 131px 296px #fff,
    1270px 1972px #fff, 39px 976px #fff, 1736px 1695px #fff, 148px 1485px #fff,
    842px 1308px #fff, 1103px 548px #fff, 1785px 585px #fff, 656px 1619px #fff,
    1210px 1598px #fff, 1667px 238px #fff, 1376px 1523px #fff,
    1428px 1394px #fff, 594px 1205px #fff, 1301px 1240px #fff, 1307px 926px #fff,
    865px 874px #fff, 762px 353px #fff, 233px 131px #fff, 415px 583px #fff,
    843px 1575px #fff, 630px 1902px #fff, 1580px 488px #fff, 167px 1529px #fff,
    1798px 117px #fff, 1397px 1082px #fff, 20px 1958px #fff, 1604px 973px #fff,
    804px 227px #fff, 1222px 895px #fff, 1985px 73px #fff, 1715px 1150px #fff,
    671px 215px #fff, 143px 656px #fff, 1445px 984px #fff, 112px 883px #fff,
    236px 1676px #fff, 1167px 1718px #fff, 1811px 1982px #fff, 676px 1647px #fff,
    1203px 1148px #fff, 1144px 1396px #fff, 1209px 1623px #fff,
    532px 1043px #fff, 1264px 841px #fff, 324px 391px #fff, 1913px 1338px #fff,
    28px 436px #fff, 1971px 65px #fff, 947px 739px #fff, 617px 1927px #fff,
    1796px 1529px #fff, 356px 837px #fff, 1987px 11px #fff, 1676px 25px #fff,
    300px 1652px #fff, 634px 321px #fff, 607px 1412px #fff, 991px 249px #fff,
    1242px 1038px #fff, 405px 1280px #fff, 1939px 28px #fff, 1041px 722px #fff,
    454px 42px #fff, 1662px 35px #fff, 836px 1582px #fff, 1682px 1013px #fff,
    565px 1879px #fff, 1481px 1674px #fff, 1435px 1332px #fff,
    1277px 1173px #fff, 136px 375px #fff, 812px 476px #fff, 47px 113px #fff,
    888px 1751px #fff, 1988px 1720px #fff, 265px 1088px #fff, 337px 1524px #fff,
    1638px 431px #fff, 1069px 1195px #fff, 1694px 634px #fff, 1404px 351px #fff,
    1890px 1603px #fff, 1376px 1555px #fff, 83px 1120px #fff, 400px 272px #fff,
    179px 1674px #fff, 1515px 1424px #fff, 1113px 884px #fff, 674px 1002px #fff,
    1948px 1500px #fff, 146px 443px #fff, 1640px 177px #fff, 150px 1089px #fff,
    428px 1824px #fff, 1875px 1054px #fff, 1295px 1661px #fff, 723px 139px #fff,
    1243px 374px #fff, 515px 832px #fff, 639px 297px #fff, 328px 261px #fff,
    1847px 957px #fff, 173px 1245px #fff, 1516px 713px #fff, 1311px 1643px #fff,
    1725px 558px #fff, 1952px 494px #fff, 645px 1937px #fff, 112px 676px #fff,
    188px 791px #fff, 1163px 1384px #fff, 1701px 597px #fff, 1911px 1695px #fff,
    56px 767px #fff, 1452px 1661px #fff, 532px 317px #fff, 1337px 409px #fff,
    929px 1553px #fff, 192px 1308px #fff, 944px 136px #fff, 1743px 1116px #fff,
    1833px 1214px #fff, 898px 293px #fff, 532px 840px #fff, 1299px 24px #fff,
    1598px 517px #fff, 1599px 1283px #fff, 1449px 1766px #fff, 156px 1733px #fff,
    398px 1743px #fff, 1809px 501px #fff, 1570px 986px #fff, 853px 1171px #fff,
    261px 459px #fff, 1731px 1904px #fff, 589px 1690px #fff, 86px 659px #fff,
    860px 838px #fff, 1530px 1495px #fff, 217px 52px #fff, 1133px 335px #fff,
    504px 252px #fff, 1750px 1093px #fff, 1010px 67px #fff, 144px 638px #fff,
    1911px 1270px #fff, 229px 1043px #fff, 1508px 1278px #fff, 899px 473px #fff,
    90px 1204px #fff, 1938px 1721px #fff, 1028px 18px #fff, 1243px 506px #fff,
    1053px 1552px #fff, 1248px 1527px #fff, 1690px 505px #fff, 1106px 274px #fff,
    1739px 1208px #fff, 895px 1242px #fff, 721px 666px #fff, 1280px 113px #fff,
    1899px 1038px #fff, 1671px 488px #fff, 1568px 330px #fff, 1553px 329px #fff,
    702px 754px #fff, 232px 1225px #fff, 385px 918px #fff, 44px 543px #fff,
    1195px 125px #fff, 1106px 272px #fff, 815px 963px #fff, 1449px 129px #fff,
    1837px 1559px #fff, 783px 1057px #fff, 1223px 607px #fff, 808px 269px #fff,
    503px 592px #fff, 1020px 166px #fff, 1468px 790px #fff, 1520px 1444px #fff,
    1901px 152px #fff, 256px 580px #fff, 1881px 1162px #fff, 1180px 1774px #fff,
    1425px 494px #fff, 683px 1955px #fff, 1258px 1713px #fff, 1337px 598px #fff,
    384px 457px #fff, 270px 904px #fff, 227px 228px #fff, 657px 133px #fff,
    189px 968px #fff, 253px 800px #fff, 1949px 1157px #fff, 1392px 76px #fff,
    1115px 1744px #fff, 1022px 740px #fff, 1762px 1560px #fff,
    1603px 1535px #fff, 284px 1451px #fff, 599px 1019px #fff, 1401px 842px #fff,
    276px 875px #fff, 1816px 830px #fff, 1758px 548px #fff, 1411px 305px #fff,
    1437px 1134px #fff, 317px 1331px #fff, 1277px 130px #fff, 1437px 556px #fff,
    1955px 294px #fff, 521px 1212px #fff, 1359px 1578px #fff, 369px 910px #fff,
    1687px 234px #fff, 69px 1995px #fff, 1301px 1037px #fff, 171px 1071px #fff,
    123px 1633px #fff, 367px 347px #fff, 1269px 439px #fff, 1144px 834px #fff,
    381px 1632px #fff, 1511px 877px #fff, 1057px 1886px #fff, 234px 231px #fff,
    1745px 600px #fff, 95px 1217px #fff, 87px 683px #fff, 1315px 165px #fff,
    560px 1072px #fff, 674px 868px #fff, 91px 446px #fff, 1478px 1135px #fff,
    703px 1209px #fff, 846px 430px #fff, 340px 205px #fff, 683px 1521px #fff,
    530px 592px #fff, 249px 1903px #fff, 449px 86px #fff, 1371px 1549px #fff,
    1815px 1474px #fff, 951px 1327px #fff, 1039px 1255px #fff, 967px 1050px #fff,
    1395px 1801px #fff, 1869px 928px #fff, 285px 1778px #fff, 1363px 1353px #fff,
    829px 856px #fff, 47px 101px #fff, 1713px 139px #fff, 1212px 17px #fff,
    1721px 1967px #fff, 979px 1118px #fff, 244px 697px #fff, 190px 387px #fff,
    786px 925px #fff, 596px 1577px #fff, 1522px 913px #fff, 415px 1190px #fff,
    1666px 1362px #fff, 1246px 1135px #fff, 1666px 81px #fff, 361px 419px #fff,
    1511px 433px #fff, 1774px 1580px #fff, 673px 216px #fff, 790px 1825px #fff,
    96px 960px #fff, 1357px 1780px #fff, 1700px 1057px #fff, 29px 765px #fff,
    1457px 625px #fff, 1670px 685px #fff, 667px 1421px #fff, 1065px 244px #fff,
    1644px 1216px #fff, 914px 406px #fff, 1941px 1234px #fff, 234px 1856px #fff,
    695px 1560px #fff, 574px 406px #fff, 723px 1680px #fff, 336px 829px #fff,
    928px 152px #fff, 1321px 250px #fff, 1860px 335px #fff, 1690px 1474px #fff,
    1214px 1405px #fff, 165px 698px #fff, 513px 1081px #fff, 1973px 1669px #fff,
    1685px 69px #fff, 340px 921px #fff, 1791px 1856px #fff, 1799px 1925px #fff,
    350px 985px #fff, 974px 1839px #fff, 988px 462px #fff, 1842px 1396px #fff,
    5px 640px #fff, 1142px 1917px #fff, 704px 491px #fff, 1704px 1869px #fff,
    1765px 1959px #fff, 1095px 1783px #fff, 502px 1405px #fff, 91px 563px #fff,
    1214px 1705px #fff, 254px 67px #fff, 1028px 836px #fff, 916px 770px #fff,
    1625px 798px #fff, 1499px 1661px #fff, 149px 515px #fff, 251px 332px #fff,
    1422px 1281px #fff, 1512px 971px #fff, 463px 617px #fff, 49px 1425px #fff,
    1327px 49px #fff, 604px 331px #fff, 920px 1929px #fff, 903px 1532px #fff,
    1535px 1341px #fff, 1px 1002px #fff, 1870px 1923px #fff, 474px 432px #fff,
    223px 1039px #fff, 1184px 1545px #fff, 1573px 1250px #fff,
    1326px 1052px #fff, 1830px 463px #fff, 847px 1687px #fff, 829px 1826px #fff,
    454px 546px #fff, 1771px 461px #fff, 365px 955px #fff, 1158px 80px #fff,
    1487px 196px #fff, 355px 997px #fff, 1328px 858px #fff, 1562px 223px #fff,
    1241px 1384px #fff, 1755px 1115px #fff, 1532px 162px #fff, 879px 270px #fff,
    1829px 94px #fff, 1718px 312px #fff, 1911px 606px #fff, 23px 1695px #fff,
    362px 305px #fff, 1154px 933px #fff, 30px 1138px #fff, 486px 1362px #fff,
    771px 1548px #fff, 253px 1810px #fff, 1870px 779px #fff, 936px 449px #fff,
    1600px 934px #fff, 205px 674px #fff, 731px 1281px #fff, 544px 1909px #fff,
    537px 229px #fff, 911px 1886px #fff, 266px 1065px #fff, 537px 865px #fff,
    1503px 906px #fff, 1463px 376px #fff, 1450px 893px #fff, 897px 74px #fff,
    1366px 1181px #fff, 1455px 151px #fff, 133px 1160px #fff, 1995px 658px #fff,
    162px 612px #fff, 130px 719px #fff, 1078px 1065px #fff, 418px 1460px #fff,
    1682px 493px #fff, 389px 122px #fff, 718px 659px #fff, 1702px 938px #fff,
    207px 1205px #fff, 1012px 1591px #fff, 1295px 165px #fff, 1626px 1536px #fff,
    340px 773px #fff, 939px 175px #fff, 207px 99px #fff, 204px 210px #fff,
    1088px 35px #fff, 798px 57px #fff, 466px 1766px #fff, 1661px 644px #fff,
    1159px 1598px #fff, 1964px 1162px #fff, 1196px 1476px #fff,
    515px 1527px #fff, 1119px 1765px #fff, 1685px 1624px #fff, 937px 590px #fff,
    1710px 207px #fff, 1626px 53px #fff, 171px 1380px #fff, 928px 98px #fff,
    225px 1969px #fff, 1731px 1986px #fff, 1092px 1924px #fff, 459px 1439px #fff,
    1436px 384px #fff, 1012px 1873px #fff, 1759px 852px #fff, 1353px 802px #fff,
    1839px 1368px #fff, 1398px 1429px #fff, 271px 296px #fff, 1839px 313px #fff,
    392px 963px #fff, 1973px 995px #fff, 895px 1744px #fff, 158px 630px #fff,
    656px 1441px #fff, 558px 1127px #fff, 410px 1514px #fff, 850px 154px #fff,
    1564px 745px #fff, 1020px 1411px #fff, 1892px 446px #fff, 363px 1158px #fff,
    730px 244px #fff, 758px 1824px #fff, 633px 859px #fff, 500px 144px #fff,
    1624px 669px #fff, 1658px 61px #fff, 1313px 974px #fff, 81px 1233px #fff,
    1220px 1937px #fff, 1381px 1820px #fff, 1043px 1717px #fff,
    1351px 426px #fff, 1740px 188px #fff, 124px 251px #fff, 426px 1553px #fff,
    355px 483px #fff, 9px 925px #fff, 1939px 386px #fff, 168px 119px #fff,
    1464px 908px #fff, 120px 257px #fff, 1345px 1514px #fff, 1357px 729px #fff,
    1047px 1352px #fff, 1574px 1574px #fff, 458px 653px #fff, 44px 594px #fff,
    1999px 668px #fff, 574px 1069px #fff, 1293px 1203px #fff, 1175px 965px #fff,
    1912px 1167px #fff, 1497px 942px #fff, 1158px 1107px #fff, 1766px 427px #fff,
    341px 1818px #fff, 765px 1655px #fff, 612px 1470px #fff, 363px 1716px #fff,
    1035px 1206px #fff, 1196px 1641px #fff, 28px 1563px #fff, 911px 935px #fff,
    1153px 158px #fff, 704px 202px #fff, 1750px 109px #fff, 1126px 61px #fff,
    1937px 568px #fff, 278px 1188px #fff, 842px 195px #fff, 135px 1872px #fff,
    1980px 733px #fff, 1636px 1919px #fff, 1832px 544px #fff, 626px 885px #fff,
    1004px 1791px #fff, 1582px 742px #fff, 1253px 441px #fff, 1078px 1914px #fff,
    1562px 1333px #fff, 327px 682px #fff, 15px 928px #fff, 154px 1380px #fff,
    965px 801px #fff, 1554px 1636px #fff, 1249px 1214px #fff, 296px 1440px #fff,
    115px 1307px #fff, 1034px 1427px #fff, 1387px 377px #fff, 1356px 1564px #fff,
    1569px 1431px #fff, 890px 1655px #fff, 1061px 1843px #fff, 858px 356px #fff,
    897px 1667px #fff, 1058px 694px #fff, 291px 402px #fff, 1746px 995px #fff,
    402px 1707px #fff, 580px 1956px #fff, 1781px 514px #fff, 1459px 1130px #fff,
    1678px 929px #fff, 1675px 897px #fff, 1976px 1116px #fff, 1447px 356px #fff,
    375px 663px #fff, 371px 807px #fff, 321px 1750px #fff, 1782px 685px #fff,
    115px 398px #fff, 189px 1312px #fff, 575px 846px #fff, 693px 167px #fff,
    213px 1088px #fff, 556px 1515px #fff, 781px 892px #fff, 1943px 128px #fff,
    147px 430px #fff, 1629px 831px #fff, 1568px 1641px #fff, 1326px 1958px #fff,
    1863px 1998px #fff, 1114px 116px #fff, 814px 231px #fff, 1322px 428px #fff,
    1605px 1376px #fff, 398px 1083px #fff, 1895px 734px #fff, 1031px 838px #fff,
    539px 1304px #fff, 1141px 1091px #fff, 1551px 81px #fff, 357px 1532px #fff,
    785px 309px #fff, 1302px 1189px #fff, 1400px 1453px #fff, 76px 359px #fff,
    1842px 679px #fff, 720px 514px #fff, 1759px 803px #fff, 1915px 962px #fff,
    1426px 1879px #fff, 1103px 133px #fff, 249px 966px #fff, 952px 996px #fff,
    415px 1117px #fff, 1533px 1436px #fff, 950px 576px #fff, 1785px 1515px #fff,
    129px 699px #fff, 704px 292px #fff, 523px 808px #fff, 748px 580px #fff,
    897px 1532px #fff, 1849px 1614px #fff, 1006px 392px #fff, 1686px 753px #fff,
    653px 1042px #fff, 1431px 975px #fff, 260px 1409px #fff, 778px 1846px #fff,
    1403px 1591px #fff, 1382px 459px #fff, 485px 294px #fff, 1478px 1546px #fff,
    696px 1019px #fff, 34px 1091px #fff, 99px 681px #fff, 42px 392px #fff,
    282px 1962px #fff, 1273px 444px #fff, 1546px 66px #fff, 1153px 437px #fff,
    1122px 1942px #fff, 319px 494px #fff, 1160px 1690px #fff, 155px 159px #fff,
    656px 820px #fff, 10px 739px #fff, 1769px 490px #fff, 786px 537px #fff,
    662px 792px #fff, 990px 1452px #fff, 270px 1794px #fff, 1600px 1424px #fff,
    208px 489px #fff, 1084px 1117px #fff, 1211px 302px #fff, 1595px 1171px #fff,
    450px 616px #fff, 432px 789px #fff, 1003px 1104px #fff, 788px 939px #fff,
    1433px 759px #fff, 852px 1392px #fff, 1152px 1284px #fff, 1255px 1375px #fff,
    378px 1008px #fff, 1085px 934px #fff, 1512px 830px #fff, 311px 1892px #fff,
    853px 387px #fff, 1643px 1537px #fff, 702px 107px #fff, 1499px 718px #fff,
    1403px 92px #fff, 1161px 759px #fff, 1215px 653px #fff, 1213px 933px #fff,
    809px 1146px #fff, 274px 1570px #fff, 1690px 980px #fff, 1239px 621px #fff,
    577px 445px #fff, 390px 495px #fff, 1253px 895px #fff, 153px 175px #fff,
    606px 573px #fff, 916px 1816px #fff, 1770px 1266px #fff, 391px 1265px #fff,
    562px 345px #fff, 658px 1336px #fff, 488px 1185px #fff, 1699px 1913px #fff,
    1381px 1158px #fff, 1656px 484px #fff, 1694px 727px #fff, 1666px 696px #fff,
    556px 566px #fff, 905px 363px #fff, 367px 409px #fff, 185px 422px #fff,
    1516px 1970px #fff, 853px 125px #fff, 572px 1422px #fff, 374px 40px #fff,
    1988px 1681px #fff, 26px 1108px #fff, 1255px 1524px #fff, 168px 1350px #fff,
    1717px 81px #fff, 1036px 869px #fff, 1522px 92px #fff, 812px 1294px #fff,
    232px 554px #fff, 197px 157px #fff, 1475px 1629px #fff, 1045px 1471px #fff,
    1654px 1248px #fff, 173px 1701px #fff, 1698px 120px #fff, 278px 1998px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 281px 1347px #fff, 1188px 1180px #fff, 249px 1931px #fff,
    515px 1909px #fff, 331px 514px #fff, 1709px 552px #fff, 725px 720px #fff,
    189px 286px #fff, 192px 1676px #fff, 292px 1138px #fff, 474px 1607px #fff,
    1256px 537px #fff, 8px 1112px #fff, 1912px 1133px #fff, 330px 1517px #fff,
    325px 1066px #fff, 1523px 555px #fff, 1234px 1266px #fff, 926px 1712px #fff,
    602px 85px #fff, 1316px 1253px #fff, 1336px 261px #fff, 597px 334px #fff,
    434px 229px #fff, 454px 721px #fff, 260px 1160px #fff, 172px 1871px #fff,
    962px 696px #fff, 687px 1626px #fff, 1345px 672px #fff, 1949px 1835px #fff,
    1565px 1078px #fff, 728px 521px #fff, 289px 555px #fff, 1551px 1656px #fff,
    390px 500px #fff, 1829px 1983px #fff, 995px 149px #fff, 1617px 1333px #fff,
    705px 1779px #fff, 603px 1328px #fff, 21px 787px #fff, 865px 1781px #fff,
    1166px 1679px #fff, 1778px 447px #fff, 2000px 232px #fff, 332px 1894px #fff,
    497px 120px #fff, 1336px 290px #fff, 760px 981px #fff, 1380px 965px #fff,
    1436px 1484px #fff, 509px 206px #fff, 1015px 489px #fff, 843px 771px #fff,
    301px 860px #fff, 1488px 967px #fff, 1341px 685px #fff, 1754px 1883px #fff,
    1445px 472px #fff, 1207px 791px #fff, 892px 29px #fff, 130px 687px #fff,
    341px 1405px #fff, 365px 1400px #fff, 981px 1790px #fff, 1424px 1772px #fff,
    1767px 1421px #fff, 1648px 52px #fff, 1474px 1442px #fff, 1426px 1440px #fff,
    494px 1526px #fff, 356px 815px #fff, 745px 26px #fff, 774px 430px #fff,
    540px 720px #fff, 1834px 1607px #fff, 556px 1112px #fff, 1825px 1272px #fff,
    761px 701px #fff, 1127px 1466px #fff, 822px 624px #fff, 741px 1042px #fff,
    1716px 457px #fff, 1206px 69px #fff, 966px 1521px #fff, 750px 1538px #fff,
    1677px 192px #fff, 1455px 1866px #fff, 507px 945px #fff, 706px 631px #fff,
    267px 794px #fff, 1420px 1717px #fff, 1124px 29px #fff, 994px 1138px #fff,
    305px 479px #fff, 117px 1659px #fff, 165px 1230px #fff, 1791px 293px #fff,
    1608px 227px #fff, 1668px 1952px #fff, 892px 424px #fff, 796px 192px #fff,
    880px 1013px #fff, 1904px 662px #fff, 1798px 249px #fff, 1458px 1731px #fff,
    240px 773px #fff, 643px 1853px #fff, 1565px 1436px #fff, 610px 1695px #fff,
    1330px 1744px #fff, 1483px 462px #fff, 759px 1496px #fff, 1731px 1088px #fff,
    1731px 517px #fff, 1511px 1106px #fff, 1289px 155px #fff, 172px 1811px #fff,
    1749px 724px #fff, 681px 349px #fff, 659px 1125px #fff, 1280px 1629px #fff,
    311px 1414px #fff, 770px 989px #fff, 756px 813px #fff, 484px 1095px #fff,
    874px 356px #fff, 1235px 736px #fff, 1783px 1880px #fff, 1899px 313px #fff,
    408px 112px #fff, 868px 389px #fff, 549px 351px #fff, 1637px 451px #fff,
    38px 354px #fff, 761px 78px #fff, 1616px 1008px #fff, 979px 1790px #fff,
    331px 779px #fff, 663px 1874px #fff, 172px 1642px #fff, 943px 1937px #fff,
    741px 1350px #fff, 1277px 1923px #fff, 1540px 1913px #fff,
    1252px 1230px #fff, 1042px 690px #fff, 790px 892px #fff, 114px 1146px #fff,
    302px 1139px #fff, 350px 40px #fff, 493px 904px #fff, 144px 80px #fff,
    782px 1716px #fff, 225px 1055px #fff, 704px 122px #fff, 1046px 511px #fff,
    939px 491px #fff, 1427px 645px #fff, 1874px 958px #fff, 1130px 1740px #fff,
    360px 1875px #fff, 1884px 1444px #fff, 124px 1787px #fff, 341px 849px #fff,
    916px 960px #fff, 658px 1980px #fff, 150px 1392px #fff, 891px 551px #fff,
    1901px 1908px #fff, 1004px 326px #fff, 1503px 131px #fff, 1368px 1301px #fff,
    241px 1316px #fff, 1782px 1685px #fff, 1196px 1194px #fff, 448px 566px #fff,
    1958px 1235px #fff, 101px 974px #fff, 973px 1675px #fff, 544px 841px #fff,
    1898px 615px #fff, 694px 1309px #fff, 1267px 1458px #fff, 1308px 238px #fff,
    31px 1089px #fff, 1442px 184px #fff, 1029px 452px #fff, 1716px 1778px #fff,
    1533px 172px #fff, 686px 1789px #fff, 883px 1466px #fff, 488px 345px #fff,
    1614px 1158px #fff, 1969px 457px #fff, 1818px 1780px #fff, 1755px 517px #fff,
    1375px 1915px #fff, 1913px 777px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 281px 1347px #fff, 1188px 1180px #fff, 249px 1931px #fff,
    515px 1909px #fff, 331px 514px #fff, 1709px 552px #fff, 725px 720px #fff,
    189px 286px #fff, 192px 1676px #fff, 292px 1138px #fff, 474px 1607px #fff,
    1256px 537px #fff, 8px 1112px #fff, 1912px 1133px #fff, 330px 1517px #fff,
    325px 1066px #fff, 1523px 555px #fff, 1234px 1266px #fff, 926px 1712px #fff,
    602px 85px #fff, 1316px 1253px #fff, 1336px 261px #fff, 597px 334px #fff,
    434px 229px #fff, 454px 721px #fff, 260px 1160px #fff, 172px 1871px #fff,
    962px 696px #fff, 687px 1626px #fff, 1345px 672px #fff, 1949px 1835px #fff,
    1565px 1078px #fff, 728px 521px #fff, 289px 555px #fff, 1551px 1656px #fff,
    390px 500px #fff, 1829px 1983px #fff, 995px 149px #fff, 1617px 1333px #fff,
    705px 1779px #fff, 603px 1328px #fff, 21px 787px #fff, 865px 1781px #fff,
    1166px 1679px #fff, 1778px 447px #fff, 2000px 232px #fff, 332px 1894px #fff,
    497px 120px #fff, 1336px 290px #fff, 760px 981px #fff, 1380px 965px #fff,
    1436px 1484px #fff, 509px 206px #fff, 1015px 489px #fff, 843px 771px #fff,
    301px 860px #fff, 1488px 967px #fff, 1341px 685px #fff, 1754px 1883px #fff,
    1445px 472px #fff, 1207px 791px #fff, 892px 29px #fff, 130px 687px #fff,
    341px 1405px #fff, 365px 1400px #fff, 981px 1790px #fff, 1424px 1772px #fff,
    1767px 1421px #fff, 1648px 52px #fff, 1474px 1442px #fff, 1426px 1440px #fff,
    494px 1526px #fff, 356px 815px #fff, 745px 26px #fff, 774px 430px #fff,
    540px 720px #fff, 1834px 1607px #fff, 556px 1112px #fff, 1825px 1272px #fff,
    761px 701px #fff, 1127px 1466px #fff, 822px 624px #fff, 741px 1042px #fff,
    1716px 457px #fff, 1206px 69px #fff, 966px 1521px #fff, 750px 1538px #fff,
    1677px 192px #fff, 1455px 1866px #fff, 507px 945px #fff, 706px 631px #fff,
    267px 794px #fff, 1420px 1717px #fff, 1124px 29px #fff, 994px 1138px #fff,
    305px 479px #fff, 117px 1659px #fff, 165px 1230px #fff, 1791px 293px #fff,
    1608px 227px #fff, 1668px 1952px #fff, 892px 424px #fff, 796px 192px #fff,
    880px 1013px #fff, 1904px 662px #fff, 1798px 249px #fff, 1458px 1731px #fff,
    240px 773px #fff, 643px 1853px #fff, 1565px 1436px #fff, 610px 1695px #fff,
    1330px 1744px #fff, 1483px 462px #fff, 759px 1496px #fff, 1731px 1088px #fff,
    1731px 517px #fff, 1511px 1106px #fff, 1289px 155px #fff, 172px 1811px #fff,
    1749px 724px #fff, 681px 349px #fff, 659px 1125px #fff, 1280px 1629px #fff,
    311px 1414px #fff, 770px 989px #fff, 756px 813px #fff, 484px 1095px #fff,
    874px 356px #fff, 1235px 736px #fff, 1783px 1880px #fff, 1899px 313px #fff,
    408px 112px #fff, 868px 389px #fff, 549px 351px #fff, 1637px 451px #fff,
    38px 354px #fff, 761px 78px #fff, 1616px 1008px #fff, 979px 1790px #fff,
    331px 779px #fff, 663px 1874px #fff, 172px 1642px #fff, 943px 1937px #fff,
    741px 1350px #fff, 1277px 1923px #fff, 1540px 1913px #fff,
    1252px 1230px #fff, 1042px 690px #fff, 790px 892px #fff, 114px 1146px #fff,
    302px 1139px #fff, 350px 40px #fff, 493px 904px #fff, 144px 80px #fff,
    782px 1716px #fff, 225px 1055px #fff, 704px 122px #fff, 1046px 511px #fff,
    939px 491px #fff, 1427px 645px #fff, 1874px 958px #fff, 1130px 1740px #fff,
    360px 1875px #fff, 1884px 1444px #fff, 124px 1787px #fff, 341px 849px #fff,
    916px 960px #fff, 658px 1980px #fff, 150px 1392px #fff, 891px 551px #fff,
    1901px 1908px #fff, 1004px 326px #fff, 1503px 131px #fff, 1368px 1301px #fff,
    241px 1316px #fff, 1782px 1685px #fff, 1196px 1194px #fff, 448px 566px #fff,
    1958px 1235px #fff, 101px 974px #fff, 973px 1675px #fff, 544px 841px #fff,
    1898px 615px #fff, 694px 1309px #fff, 1267px 1458px #fff, 1308px 238px #fff,
    31px 1089px #fff, 1442px 184px #fff, 1029px 452px #fff, 1716px 1778px #fff,
    1533px 172px #fff, 686px 1789px #fff, 883px 1466px #fff, 488px 345px #fff,
    1614px 1158px #fff, 1969px 457px #fff, 1818px 1780px #fff, 1755px 517px #fff,
    1375px 1915px #fff, 1913px 777px #fff;
}

#stars3 {
  overflow-y: hidden;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 696px 1295px #fff, 1741px 25px #fff, 245px 166px #fff,
    347px 364px #fff, 864px 918px #fff, 284px 1344px #fff, 773px 744px #fff,
    182px 561px #fff, 1379px 1936px #fff, 1303px 121px #fff, 281px 888px #fff,
    128px 1556px #fff, 1642px 343px #fff, 67px 1234px #fff, 1856px 1463px #fff,
    1804px 1911px #fff, 951px 1280px #fff, 600px 962px #fff, 1774px 1013px #fff,
    1356px 966px #fff, 1977px 331px #fff, 1551px 464px #fff, 209px 211px #fff,
    1541px 1466px #fff, 413px 641px #fff, 663px 1833px #fff, 526px 444px #fff,
    1540px 921px #fff, 583px 597px #fff, 1955px 826px #fff, 1926px 1526px #fff,
    457px 787px #fff, 272px 1792px #fff, 716px 1879px #fff, 1633px 652px #fff,
    1337px 1217px #fff, 1938px 1950px #fff, 1182px 1165px #fff,
    1204px 442px #fff, 189px 1707px #fff, 413px 1483px #fff, 229px 1348px #fff,
    988px 282px #fff, 414px 1616px #fff, 1001px 1570px #fff, 1822px 838px #fff,
    1900px 1230px #fff, 742px 779px #fff, 1032px 132px #fff, 1459px 1098px #fff,
    1976px 1559px #fff, 1386px 951px #fff, 1354px 1419px #fff, 811px 1739px #fff,
    1744px 32px #fff, 1080px 1721px #fff, 1352px 782px #fff, 1537px 690px #fff,
    1060px 1952px #fff, 796px 1137px #fff, 326px 1843px #fff, 795px 967px #fff,
    472px 1332px #fff, 308px 1553px #fff, 801px 525px #fff, 256px 90px #fff,
    1773px 1617px #fff, 1418px 1659px #fff, 396px 284px #fff, 1648px 1840px #fff,
    1698px 1618px #fff, 1px 712px #fff, 28px 1127px #fff, 1372px 1520px #fff,
    1051px 743px #fff, 268px 1209px #fff, 1383px 525px #fff, 226px 96px #fff,
    1578px 1884px #fff, 823px 83px #fff, 354px 7px #fff, 1741px 1511px #fff,
    465px 1575px #fff, 679px 1250px #fff, 515px 1765px #fff, 1614px 1295px #fff,
    1124px 1347px #fff, 1380px 43px #fff, 493px 387px #fff, 1328px 75px #fff,
    805px 86px #fff, 1284px 280px #fff, 1988px 807px #fff, 181px 483px #fff,
    745px 1814px #fff, 1380px 937px #fff, 96px 325px #fff, 1330px 1929px #fff,
    1758px 689px #fff, 1952px 53px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 696px 1295px #fff, 1741px 25px #fff, 245px 166px #fff,
    347px 364px #fff, 864px 918px #fff, 284px 1344px #fff, 773px 744px #fff,
    182px 561px #fff, 1379px 1936px #fff, 1303px 121px #fff, 281px 888px #fff,
    128px 1556px #fff, 1642px 343px #fff, 67px 1234px #fff, 1856px 1463px #fff,
    1804px 1911px #fff, 951px 1280px #fff, 600px 962px #fff, 1774px 1013px #fff,
    1356px 966px #fff, 1977px 331px #fff, 1551px 464px #fff, 209px 211px #fff,
    1541px 1466px #fff, 413px 641px #fff, 663px 1833px #fff, 526px 444px #fff,
    1540px 921px #fff, 583px 597px #fff, 1955px 826px #fff, 1926px 1526px #fff,
    457px 787px #fff, 272px 1792px #fff, 716px 1879px #fff, 1633px 652px #fff,
    1337px 1217px #fff, 1938px 1950px #fff, 1182px 1165px #fff,
    1204px 442px #fff, 189px 1707px #fff, 413px 1483px #fff, 229px 1348px #fff,
    988px 282px #fff, 414px 1616px #fff, 1001px 1570px #fff, 1822px 838px #fff,
    1900px 1230px #fff, 742px 779px #fff, 1032px 132px #fff, 1459px 1098px #fff,
    1976px 1559px #fff, 1386px 951px #fff, 1354px 1419px #fff, 811px 1739px #fff,
    1744px 32px #fff, 1080px 1721px #fff, 1352px 782px #fff, 1537px 690px #fff,
    1060px 1952px #fff, 796px 1137px #fff, 326px 1843px #fff, 795px 967px #fff,
    472px 1332px #fff, 308px 1553px #fff, 801px 525px #fff, 256px 90px #fff,
    1773px 1617px #fff, 1418px 1659px #fff, 396px 284px #fff, 1648px 1840px #fff,
    1698px 1618px #fff, 1px 712px #fff, 28px 1127px #fff, 1372px 1520px #fff,
    1051px 743px #fff, 268px 1209px #fff, 1383px 525px #fff, 226px 96px #fff,
    1578px 1884px #fff, 823px 83px #fff, 354px 7px #fff, 1741px 1511px #fff,
    465px 1575px #fff, 679px 1250px #fff, 515px 1765px #fff, 1614px 1295px #fff,
    1124px 1347px #fff, 1380px 43px #fff, 493px 387px #fff, 1328px 75px #fff,
    805px 86px #fff, 1284px 280px #fff, 1988px 807px #fff, 181px 483px #fff,
    745px 1814px #fff, 1380px 937px #fff, 96px 325px #fff, 1330px 1929px #fff,
    1758px 689px #fff, 1952px 53px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
