.landing-page-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: left;
}

.project-name_main {
  font-size: 75px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  height: 55px;
}

.project-name_second {
  font-size: 75px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.description {
  font-size: 30px;
  font-weight: 300;
  margin-top: 4%;
  margin-bottom: 6%;
  display: flex;
  align-items: center;
}

.quiz-link {
  text-decoration: unset;
  color: black;
  font-size: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  width: fit-content;
}

.quiz-link:hover {
  color: crimson;
  transition: 0.3s;
}

.links-text {
  cursor: pointer;
}

.links-text:hover {
  color: #1976d2;
  transition: 0.2s;
}

.column {
  width: 80%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.divider {
  width: 100%;
  border-bottom: solid 1px grey;
}

.benefits-container {
  width: 67%;
  align-self: center;
  margin-top: 100px;
  display: flex;
  flex-flow: row;
  gap: 5%;
  margin-bottom: 100px;
}

.component {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1 1 0px;
}

.text {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 200;
}

img {
  opacity: 0.5;
}

.links-container {
  display: flex;
  justify-content: space-around;
  padding: 0 16%;
}

p {
  font-size: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.small-quiz-link {
  text-decoration: unset;
  color: black;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: fit-content;
}

.small-quiz-link:hover {
  color: crimson;
  transition: 0.2s;
}

.who-we-are {
  display: flex;
  padding: 8% 15%;
  place-content: center;
}

.why-necessary {
  display: flex;
  padding: 8% 15%;
  place-content: center;
}

.section-container {
  display: flex;
  flex-flow: column;
  align-items: end;
  margin-top: 10px;
  padding-right: 100px;
}

.section-name {
  font-size: 75px;
  font-weight: 500;
  height: 55px;
  margin: 0;
  white-space: nowrap;
}

.section-name-bold {
  font-size: 75px;
  font-weight: 800;
  height: 55px;
  margin: 0;
  white-space: nowrap;
}

.info {
  margin-top: 0;
}

.info-container {
  width: 50%;
}

.ready-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.start-now-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: white;
  margin: 5%;
  gap: 40px;
  background: white;
  color: black;
  mix-blend-mode: screen;
  padding: 4% 3%;
}

.start-now-container_text {
  font-size: 200px;
  font-weight: 800;
  margin: 0;
}

.ready-text {
  font-size: 35px;
  font-weight: 400;
  padding-left: 50px;
}

.ready-quiz-link {
  text-decoration: unset;
  color: black;
  font-size: 40px;
  font-weight: 600;
  color: #1976d2;
}

.ready-quiz-link:hover {
  color: crimson;
  transition: 0.2s;
}

.how {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
}

.how-text {
  font-size: 35px;
  font-weight: 500;
  margin: 0;
}

.how-svg {
  height: 50px !important;
  width: 50px !important;
}

.link-button {
  align-self: center;
  width: 200px;
  margin-bottom: 200px !important;
  background-color: crimson !important;
}

.link-button_text {
  text-decoration: unset;
  color: black;
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  padding: 0 23%;
}

.footer-text {
  font-size: 16px;
}

@media only screen and (max-width: 1910px) {
  .project-name_main {
    height: unset;
  }
}

@media only screen and (max-width: 1600px) {
  .project-name_main {
    font-size: 60px;
    height: unset;
  }
  .project-name_second {
    font-size: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .who-we-are {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .why-necessary {
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .info-container {
    width: 80%;
  }

  .start-now-container {
    width: 80%;
  }
}
