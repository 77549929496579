.button-group {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-style {
  padding: 0 !important;
  margin: 0;
}

.button-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.prediction-button-grp {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.prediction-text {
  font-size: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.info-text {
  margin: 2% 10%;
}

.divider {
  width: 100%;
  border-bottom: solid 1px grey;
}

.diagram-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 4% 8%;
}
